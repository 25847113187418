import React, { useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ButtonGroup from "../../../Elements/ButtonGroup"

import { generateFormField } from "elements/Form/services/form"
import { healthSurveySchema } from "../utils/healthSurveySchema"
import { medEnsureSteps } from "../../utils/medEnsureSteps"
import { MedEnsureContext } from "../../MedEnsureContext/MedEnsureContext"

import { heightAndWeightOptions } from "../../../Elements/utils/heightAndWeightOptions"

let { parseFormField } = require("../../../../services/airtable")

const HealthSurvey = ({ pageContext, location }) => {
  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)
  let formFields = pageContext.formFields
  let validationSchema = healthSurveySchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSubmitMedEnsureHealthSurvey = (values) => {
    medEnsureDispatch({
      type: "SAVE_HEALTH_SURVEY",
      payload: {
        ...values,
      },
    })
    medEnsureDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...medEnsureState,
        ...values,
      },
    })
    navigate(pageContext.nextPath)
  }

  useEffect(() => {
    medEnsureDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      currentStep={3}
      totalSteps={4}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      steps={medEnsureSteps}
      location={location}
      path={pageContext?.module.path}
      hasMobileStepper={true}
    >
      <Container isCentered>
        <Formik
          enableReinitialize
          initialValues={{ ...medEnsureState }}
          onSubmit={handleSubmitMedEnsureHealthSurvey}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="content"
                  isRequired={section?.isSectionRequired}
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}
                  <ol>
                    {section?.fields.map((field, index) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <li key={index}>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: {
                                ...field,
                                ...(field?.name === "height" && {
                                  options: heightAndWeightOptions.height,
                                }),
                                ...(field?.name === "weight" && {
                                  options: heightAndWeightOptions.weight,
                                }),
                              },
                              values,
                              setFieldValue,
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </li>
                        )
                      }
                      return null
                    })}
                  </ol>
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ButtonGroup
                back={{
                  link: pageContext.backPath,
                }}
                next={{
                  label: `Next: ${pageContext.module.next}`,
                  link: pageContext.nextPath,
                  type: "submit",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default HealthSurvey
